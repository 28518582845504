import { useState, useEffect, useMemo } from 'react';

export type MediaBreakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

const determineDisplaySize = (width: number): MediaBreakpoint => {
    if (width < 480) {
        return 'xs';
    }
    if (width < 768) {
        return 'sm';
    }
    if (width < 1024) {
        return 'md';
    }
    if (width < 1440) {
        return 'lg';
    }
    if (width < 1920) {
        return 'xl';
    }
    return 'xxl';
};

export const useMediaBreakpoint = (): MediaBreakpoint => {
    const [currentDisplaySize, setCurrentDisplaySize] = useState(determineDisplaySize(window.innerWidth));

    useEffect(() => {
        const handler = () => setCurrentDisplaySize(determineDisplaySize(window.innerWidth));
        window.addEventListener('resize', handler);
        return () => window.removeEventListener('resize', handler);
    }, []);

    return useMemo(() => currentDisplaySize, [currentDisplaySize]);
};