import classNames from 'classnames';
import { HeaderLogo } from 'components/Layout/Header/HeaderLogo';
import { SgwtAccountCenter } from 'components/Layout/Header/SgwtAccountCenter';
import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import SgMarketLogo from 'images/sg_market.svg';
import { useMediaBreakpoint } from 'components/hooks/media/useMediaBreakpoint';
import { RoutePaths } from 'routes/RoutePaths';

export const Header: React.FC = () => {
    const mediaBreakpoint = useMediaBreakpoint();
    const headerRef = useRef<HTMLDivElement>(null);

    const navbarClassName = {
        'navbar-sm': ['xs', 'sm'].includes(mediaBreakpoint),
        'navbar-md': ['md', 'lg', 'xl'].includes(mediaBreakpoint),
        'navbar-lg': ['xxl'].includes(mediaBreakpoint),
    };

    return (
        <header className="sticky-top" ref={headerRef}>
            <nav className={classNames('navbar navbar-expand-lg bg-lvl1 d-flex align-items-stretch py-0', navbarClassName)}>
                <div className="navbar-title">
                    <NavLink to={RoutePaths.Home.route} className="navbar-title-link">
                        <HeaderLogo />
                    </NavLink>
                </div>
                <div className="navbar-toolbar">
                    <SgwtAccountCenter />
                    <svg className="overflow-visible d-none d-sm-block" width="145" height="32">
                        <image xlinkHref={SgMarketLogo} x="0" y="0" height="16" width="145"></image>
                        <text className="fw-medium" x="5" y="32" height="16" width="145">
                            Investment Reco
                        </text>
                    </svg>
                </div>
            </nav>
        </header>
    );
};
