import { AppContextAction } from './AppContext/AppContextActions';
import { AppContextReducer } from './AppContext/AppContextReducer';
import { NormalizrAction } from './Normalizr/NormalizrAction';
import { NormalizrReducer } from './Normalizr/NormalizrReducer';
import { InvestmentRecosActions } from './InvestmentRecos/InvestmentRecosActions';
import { InvestmentRecosReducer } from './InvestmentRecos/InvestmentRecosReducer';
import { CurrenciesAction } from './Currencies/CurrenciesAction';
import { CurrenciesReducer } from './Currencies/CurrenciesReducer';
import { PersonsReducer } from './Persons/PersonsReducer';
import { AnalystsAction } from './Persons/PersonsActions';
import { AnalyticsActions } from './Analytics/AnalyticsActions';
import { AnalyticsReducer } from './Analytics/AnalyticsReducer';
import { StrategiesAction } from './Strategies/StrategiesActions';
import { StrategiesReducer } from './Strategies/StrategiesReducer';
import { ModalsActions } from './Modals/ModalsActions';
import { ModalsReducer } from './Modals/ModalsReducer';
import { AsyncTypeaheadActions } from './AsyncTypeahead/AsyncTypeaheadActions';
import { AsyncTypeaheadReducer } from './AsyncTypeahead/AsyncTypeaheadReducer';
import { AttachmentsAction } from './Attachments/AttachmentsActions';
import { AttachmentsReducer } from './Attachments/AttachmentsReducer';
import { Reducer as BaseReducer, combineReducers } from './storeHelper';

export type Action =
    | { type: 'TEST' }
    | AppContextAction
    | NormalizrAction
    | InvestmentRecosActions
    | CurrenciesAction
    | AnalystsAction
    | AnalyticsActions
    | StrategiesAction
    | ModalsActions
    | AsyncTypeaheadActions
    | AttachmentsAction

const reducers = {
    appContext: AppContextReducer,
    entities: NormalizrReducer,
    investmentRecos: InvestmentRecosReducer,
    currencies: CurrenciesReducer,
    persons: PersonsReducer,
    analytics: AnalyticsReducer,
    strategies: StrategiesReducer,
    modals: ModalsReducer,
    asyncTypeahead: AsyncTypeaheadReducer,
    attachments: AttachmentsReducer,
};

type StateReducersMapObject = typeof reducers;
type StateKeys = keyof StateReducersMapObject;
export type State = { [key in StateKeys]: ReturnType<StateReducersMapObject[key]> };

export const reducer = combineReducers<State, Action>(reducers);

export const initialState = reducer({} as State, {} as any);

export type Reducer<S = any> = BaseReducer<S, Action>;