import { generatePath } from 'react-router-dom'

export const RoutePaths = {
    Errors: {
        NotAuthorized: {
            route: '/error/not-authorized',
            url: () => RoutePaths.Errors.NotAuthorized.route,
        },
        NotAuthorizedContactUs: {
            route: '/error/not-authorized/contact-us',
            url: () => RoutePaths.Errors.NotAuthorizedContactUs.route,
        },
        NotFound: {
            route: '*',
            url: () => '/error/not-found',
        },
    },
    Home: {
        route: '/',
        url: () => RoutePaths.Home.route,
    },
    CreateReco: {
        route: '/create',
        url: (draftAttachementId?: number) => RoutePaths.CreateReco.route + getQueryParameters({ draftAttachementId }),
    },
    EditReco: {
        route: '/edit/:editId',
        url: (investmentRecoHeadId: number) => generatePath(RoutePaths.EditReco.route, { editId: `${investmentRecoHeadId}` }),
    },
    ViewReco: {
        route: '/view/:viewId',
        url: (investmentRecoHeadId: number) => generatePath(RoutePaths.ViewReco.route, { viewId: `${investmentRecoHeadId}` }),
    },
} as const;

export type RouteParams = {
    Errors: {
        NotAuthorized: ExtractRouteParams<typeof RoutePaths.Errors.NotAuthorized.route, string>,
        NotFound: ExtractRouteParams<typeof RoutePaths.Errors.NotFound.route, string>,
    },
    Home: ExtractRouteParams<typeof RoutePaths.Home.route, string>,
    EditReco: ExtractRouteParams<typeof RoutePaths.EditReco.route, string>,
    ViewReco: ExtractRouteParams<typeof RoutePaths.ViewReco.route, string>,
    CreateReco: ExtractRouteParams<typeof RoutePaths.CreateReco.route, string>,
};

const getQueryParameters = (parameters: Record<string, string | number | undefined | Date | (string | number | undefined | Date)[]>) => {
    const search = new URLSearchParams(
        Object.entries(parameters)
            .filter(entry => entry[1])
            .map(([key, value]) => ([key, value?.toString() || ''])),
    ).toString();
    return search ? `?${search}` : '';
};

type ExtractRouteOptionalParam<T extends string, U = string | number | boolean> = T extends `${infer Param}?`
    ? { [_ in Param]?: U }
    : T extends `${infer Param}*`
    ? { [_ in Param]?: U }
    : T extends `${infer Param}+`
    ? { [_ in Param]: U }
    : { [_ in T]: U };

/* eslint-disable */
type ExtractRouteParams<T extends string, U = string | number | boolean> = string extends T
    ? { [_ in string]?: U }
    : T extends `${infer _}:${infer ParamWithOptionalRegExp}/${infer Rest}`
    ? ParamWithOptionalRegExp extends `${infer Param}(${infer _})`
    ? ExtractRouteOptionalParam<Param, U> & ExtractRouteParams<Rest, U>
    : ExtractRouteOptionalParam<ParamWithOptionalRegExp, U> & ExtractRouteParams<Rest, U>
    : T extends `${infer _}:${infer ParamWithOptionalRegExp}`
    ? ParamWithOptionalRegExp extends `${infer Param}(${infer _})`
    ? ExtractRouteOptionalParam<Param, U>
    : ExtractRouteOptionalParam<ParamWithOptionalRegExp, U>
    : unknown;
