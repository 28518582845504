import { InstrumentModel } from 'services/ApiService/InvestReco/InvestRecoApiClient';
import { getInstrumentKey } from 'services/InvestmentRecosService';
import { ApiRequest, Reducer } from 'store';

export interface IInstrumentRequestMap {
    readonly [key: string]: ApiRequest<InstrumentModel>;
}

export const initialState = {
    marketCodes: {
        isFetching: false as boolean,
        didInvalidate: true as boolean,
        data: undefined as string[] | null | undefined,
    },
    instruments: {} as IInstrumentRequestMap,
    instrumentsByCodes: {
        isFetching: false as boolean,
        didInvalidate: true as boolean,
        data: undefined as InstrumentModel[] | null | undefined,
    },
} as const;

export type AnalyticsState = typeof initialState;

export const AnalyticsReducer: Reducer<AnalyticsState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case '@ANALYTICS/FETCH_MARKET_CODES':
            return {
                ...state,
                marketCodes: {
                    ...state.marketCodes,
                    isFetching: true,
                },
            };
        case '@ANALYTICS/FETCH_MARKET_CODES_SUCCESS':
            return {
                ...state,
                marketCodes: {
                    ...state.marketCodes,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.response,
                },
            };
        case '@ANALYTICS/FETCH_MARKET_CODES_FAILURE':
            return {
                ...state,
                marketCodes: {
                    ...state.marketCodes,
                    isFetching: false,
                    didInvalidate: true,
                },
            };
        case '@ANALYTICS/SEARCH_INSTRUMENTS_BY_CODE':
            return {
                ...state,
                instrumentsByCodes: {
                    ...state.instrumentsByCodes,
                    isFetching: true,
                },
            };
        case '@ANALYTICS/SEARCH_INSTRUMENTS_BY_CODE_SUCCESS':
            return {
                ...state,
                instrumentsByCodes: {
                    ...state.instrumentsByCodes,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.response,
                },
            };
        case '@ANALYTICS/SEARCH_INSTRUMENTS_BY_CODE_FAILURE':
            return {
                ...state,
                instrumentsByCodes: {
                    ...state.instrumentsByCodes,
                    isFetching: false,
                    didInvalidate: true,
                },
            };
        case '@ANALYTICS/CLEAR_INSTRUMENTS':
            return {
                ...state,
                instrumentsByCodes: {
                    ...state.instrumentsByCodes,
                    data: undefined,
                },
            };
        case '@ANALYTICS/FETCH_INSTRUMENT':
            return {
                ...state,
                instruments: {
                    ...state.instruments,
                    [getInstrumentKey(action.payload.identifierType, action.payload.query)]: {
                        ...state.instruments[getInstrumentKey(action.payload.identifierType, action.payload.query)],
                        isFetching: true,
                    },
                },
            };
        case '@ANALYTICS/FETCH_INSTRUMENT_SUCCESS':
            return {
                ...state,
                instruments: {
                    ...state.instruments,
                    [getInstrumentKey(action.payload.identifierType, action.payload.query)]: {
                        ...state.instruments[getInstrumentKey(action.payload.identifierType, action.payload.query)],
                        isFetching: false,
                        didInvalidate: false,
                        data: action.payload.instrument,
                    },
                },
            };
        case '@ANALYTICS/FETCH_INSTRUMENT_FAILURE':
            return {
                ...state,
                instruments: {
                    ...state.instruments,
                    [getInstrumentKey(action.payload.identifierType, action.payload.query)]: {
                        ...state.instruments[getInstrumentKey(action.payload.identifierType, action.payload.query)],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };
        default:
            return state;
    }
};