import classNames from 'classnames';
import * as React from 'react';
import { ToastContainer } from 'react-toastify';
import { ErrorBoundary } from 'components/common/ErrorBoundary';
import { Header } from './Header';
import { HelpCenter } from './HelpCenter';
import { Footer } from './Footer';
import { SplashScreen } from './SplashScreen';
import { SgwtConnect } from './SgwtConnect';
import 'react-toastify/dist/ReactToastify.css';

interface IProps {
    children: React.ReactNode;
}

export const Layout: React.FC<IProps> = ({ children }) => {
    return (
        <div>
            <Header />
            <main className="d-flex justify-content-center px-4 mt-4 mb-4" style={{ minHeight: 'calc(100vh - 13.75em)' }}>
                <ErrorBoundary>{children}</ErrorBoundary>
            </main>
            <Footer />
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                closeButton={false}
                icon={false}
                newestOnTop
                closeOnClick
                pauseOnFocusLoss
                draggable
                pauseOnHover
                toastClassName={context => classNames(
                    'toast fade show',
                    {
                        'toast-danger': context?.type === 'error',
                        'toast-info': context?.type === 'info',
                        'toast-success': context?.type === 'success',
                        'toast-warning': context?.type === 'warning',
                        'toast-primary': context?.type === 'default',
                    },
                )}
                bodyClassName={() => ''}
                progressClassName={context => classNames(
                    context?.defaultClassName,
                    {
                        'bg-danger': context?.type === 'error',
                        'bg-info': context?.type === 'info',
                        'bg-success': context?.type === 'success',
                        'bg-warning': context?.type === 'warning',
                        'bg-primary': context?.type === 'default',
                    },
                )}
                progressStyle={{ height: '3px', position: 'inherit' }}
            />
            <HelpCenter />
            <SgwtConnect />
            <SplashScreen />
        </div >);
};
