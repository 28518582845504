export const createFetchAppContextAction = () => {
    return {
        type: '@APP_CONTEXT/FETCH_APP_CONTEXT',
    } as const;
};

export const createFetchAppContextSuccessAction = (environment?: string | null, loggedUserIcid?: string | null, privileges?: string[] | null) => {
    return {
        type: '@APP_CONTEXT/FETCH_APP_CONTEXT_SUCCESS',
        payload: {
            environment,
            loggedUserIcid,
            privileges,
        },
    } as const;
};

export const createFetchAppContextFailureAction = () => {
    return {
        type: '@APP_CONTEXT/FETCH_APP_CONTEXT_FAILURE',
    } as const;
};

export const createSignOutAction = () => {
    return {
        type: '@APP_CONTEXT/SIGN_OUT',
    } as const;
};

export type AppContextAction =
    | ReturnType<typeof createFetchAppContextAction>
    | ReturnType<typeof createFetchAppContextSuccessAction>
    | ReturnType<typeof createFetchAppContextFailureAction>
    | ReturnType<typeof createSignOutAction>;
