import { ThunkAction } from 'components/common/AppProvider';
import { AccessRight, AssetClassType, InvestmentRecoModel, InvestmentRecoResponseModel, InvestmentRecoSaveDto, ProductRecoStatus, Recommendation } from 'services/ApiService/InvestReco/InvestRecoApiClient';
import { PromiseStore } from 'services/PromiseStore';
import {
    createFetchInvestmentRecommendationsAction,
    createFetchInvestmentRecommendationsFailureAction,
    createFetchInvestmentRecommendationsSuccessAction,
    createFetchInvestmentRecommendationByIdAction,
    createFetchInvestmentRecommendationByIdSuccessAction,
    createFetchInvestmentRecommendationByIdFailureAction,
    createFetchUserAccessRightsOnInvestmentRecommendationAction,
    createFetchUserAccessRightsOnInvestmentRecommendationFailureAction,
    createFetchUserAccessRightsOnInvestmentRecommendationSuccessAction,
    createDeleteInvestmentRecommendationSuccessAction,
    createCreateInvestmentRecommendationSuccessAction,
    createPublishSgmPublicationSuccessAction,
    createPublishSgmPublicationAction,
    createPublishSgmPublicationFailureAction,
    createSetSearchTermsAction,
    createClearFilesAction,
    createUpdateInvestmentRecommendationAction,
    createUpdateInvestmentRecommendationSuccessAction,
    createUpdateInvestmentRecommendationFailureAction,
    createCreateInvestmentRecommendationAction,
    createCreateInvestmentRecommendationFailureAction,
    createCloseProductRecoAction,
    createCloseProductRecoSuccessAction,
    createCloseProductRecoFailureAction,
} from './InvestmentRecosActions';
import {
    FilesApi, InvestRecoApiSchema, InvestmentRecosApi,
} from 'services/ApiService';
import { logError } from 'services/Logger';
import { normalize } from 'normalizr';
import { mergeInvestRecoEntities, removeInvestRecoEntities } from 'store/Normalizr/NormalizrAction';
import { abortRequests } from 'services/ApiService/Http';
import { createFetchInstrumentSuccessAction } from 'store/Analytics/AnalyticsActions';
import { IdentifierType } from 'services/InstrumentsService';

export const searchInvestmentRecos = (includePrivate: boolean,
    skip: number,
    take: number,
    editableOnly: boolean,
    icId?: string,
    identifier?: string,
    instrumentName?: string,
    fromPublicationDate?: Date,
    untilPublicationDate?: Date,
    fromLiveStatusEndDate?: Date,
    untilLiveStatusEndDate?: Date,
    recommendation?: Recommendation,
    status?: ProductRecoStatus): ThunkAction => (dispatch, getState) => {

        const state = getState();

        if (!state?.investmentRecos?.investmentRecosResponse.didInvalidate || state.investmentRecos.investmentRecosResponse?.isFetching) {
            const promise = PromiseStore.get<void>('searchInvestmentRecos');
            if (promise) {
                return promise;
            }
        }

        dispatch(createFetchInvestmentRecommendationsAction(
            skip,
            take,
            icId,
            identifier,
            instrumentName,
            fromPublicationDate,
            untilPublicationDate,
            fromLiveStatusEndDate,
            untilLiveStatusEndDate));

        abortRequests('/api/v2/investment-recos/search', 'GET');

        const fetchTask = InvestmentRecosApi
            .searchInvestmentRecos(undefined,
                undefined,
                icId,
                undefined,
                undefined,
                undefined,
                undefined,
                identifier,
                instrumentName,
                undefined,
                undefined,
                undefined,
                undefined,
                fromPublicationDate,
                untilPublicationDate,
                undefined,
                undefined,
                undefined,
                undefined,
                fromLiveStatusEndDate,
                untilLiveStatusEndDate,
                undefined,
                recommendation,
                status,
                undefined,
                includePrivate,
                editableOnly,
                skip,
                take)
            .then((searchInvestmentRecos) => {
                const normalizedData = normalize(searchInvestmentRecos.results, InvestRecoApiSchema.ProductRecoModelSchemaArray);
                dispatch(mergeInvestRecoEntities(normalizedData.entities));
                dispatch(createFetchInvestmentRecommendationsSuccessAction(normalizedData.result,
                    searchInvestmentRecos.totalPages ?? 1,
                    searchInvestmentRecos.totalResults ?? 0,
                    skip,
                    take,
                    icId,
                    identifier,
                    instrumentName,
                    fromPublicationDate,
                    untilPublicationDate,
                    fromLiveStatusEndDate,
                    untilLiveStatusEndDate,
                    recommendation,
                    status));
                return;
            })
            .catch((error) => {
                if (error.name !== 'AbortError') {
                    dispatch(createFetchInvestmentRecommendationsFailureAction(
                        skip,
                        take,
                        icId,
                        identifier,
                        instrumentName,
                        fromPublicationDate,
                        untilPublicationDate,
                        fromLiveStatusEndDate,
                        untilLiveStatusEndDate, 
                        recommendation,
                        status));
                    logError(error);
                }
                throw error;
            });

        PromiseStore.set(fetchTask, 'searchInvestmentRecos');

        return fetchTask;
    };

export const setSearchTerms = (searchTerms: string | null, 
    skip: number, 
    take: number, 
    startDate?: Date, 
    endDate?: Date, 
    icIds?: string, 
    instrumentName?:string,
    identifier?: string,
    fromLiveStatusEndDate?: Date,
    untilLiveStatusEndDate?: Date,
    recommendation?: Recommendation,
    status?: ProductRecoStatus): ThunkAction<void> => (dispatch) => {
    dispatch(createSetSearchTermsAction(searchTerms, skip, take, startDate, endDate, fromLiveStatusEndDate, untilLiveStatusEndDate, icIds, instrumentName, identifier, recommendation, status));
};

export const getInvestmentRecoById = (id: number): ThunkAction => async (dispatch, getState) => {
    const state = getState();

    if (state?.investmentRecos?.investmentRecoById && (state.investmentRecos.investmentRecoById[id]?.isFetching || !state.investmentRecos.investmentRecoById[id]?.didInvalidate)) {
        const promise = PromiseStore.get('getInvestmentRecoById', id);
        if (promise) {
            return promise;
        }
    }

    try {
        const fetchTask = (async () => {
            const data = await InvestmentRecosApi.getInvestmentRecoById(id);
            const normalizedData = normalize(data, InvestRecoApiSchema.InvestmentRecoModelSchema);
            dispatch(mergeInvestRecoEntities(normalizedData.entities));
            dispatch(createFetchInvestmentRecommendationByIdSuccessAction(id));
            data.products?.map(product => product.instruments?.map(instrument => {
                const listIdentifier = [instrument?.bloombergCode, instrument?.ricCode, instrument?.isinCode, instrument?.sedolCode];
                if (instrument && instrument.assetClassType != AssetClassType.Credit) {
                    for (let index = 0; index < listIdentifier.length; index++) {
                        if (listIdentifier[index]?.length) {
                            dispatch(createFetchInstrumentSuccessAction(listIdentifier[index], Object.values(IdentifierType)[index], instrument));
                        }
                    }
                }
            }));
            return;
        })();

        PromiseStore.set(fetchTask, 'getInvestmentRecoById', id);

        dispatch(createFetchInvestmentRecommendationByIdAction(id));

        return await fetchTask;
    }
    catch (error) {
        dispatch(createFetchInvestmentRecommendationByIdFailureAction(id));
        logError(error);
        throw error;
    }
};

export const getAccessRightsForCurrentUser = (id: number): ThunkAction<Promise<AccessRight[]>> => async (dispatch, getState) => {
    const state = getState();

    if (state?.investmentRecos?.accessRightById && (state.investmentRecos.accessRightById[id]?.isFetching || !state.investmentRecos.accessRightById[id]?.didInvalidate)) {
        const promise = PromiseStore.get<AccessRight[]>('getAccessRightsForCurrentUser', id);
        if (promise) {
            return promise;
        }
    }

    try {
        const fetchTask = (async () => {
            const data = await InvestmentRecosApi.getAccessRightsForCurrentUser(id);
            dispatch(createFetchUserAccessRightsOnInvestmentRecommendationSuccessAction(id, data));
            return data;
        })();

        PromiseStore.set(fetchTask, 'getAccessRightsForCurrentUser', id);
        dispatch(createFetchUserAccessRightsOnInvestmentRecommendationAction(id));
        return await fetchTask;
    }
    catch (error) {
        dispatch(createFetchUserAccessRightsOnInvestmentRecommendationFailureAction(id));
        logError(error);
        throw error;
    }
};

export const updateInvestmentReco = (id: number, request: InvestmentRecoSaveDto): ThunkAction<Promise<InvestmentRecoModel>> => async (dispatch, getState) => {
    const state = getState();

    if (state?.investmentRecos?.updateRequest && state.investmentRecos.updateRequest.isSubmitting) {
        const promise = PromiseStore.get<InvestmentRecoModel>('updateInvestmentRecoId', id);
        if (promise) {
            return promise;
        }
    }

    dispatch(createUpdateInvestmentRecommendationAction());

    try {
        let data;
        if (state.investmentRecos.files[0]) {
            const attachedFileId = await FilesApi.createFile(state.investmentRecos.files);
            data = await InvestmentRecosApi.updateInvestmentReco(id, { ...request, attachedFileId: attachedFileId });
        }
        else {
            data = await InvestmentRecosApi.updateInvestmentReco(id, request);
        }
        const normalizedData = normalize(data, InvestRecoApiSchema.InvestmentRecoModelSchema);
        dispatch(mergeInvestRecoEntities(normalizedData.entities));
        dispatch(createClearFilesAction());
        dispatch(createUpdateInvestmentRecommendationSuccessAction(id));
        return data;
    }
    catch (error) {
        logError(error);
        dispatch(createUpdateInvestmentRecommendationFailureAction());
        throw error;
    }
};

export const deleteInvestmentReco = (id: number): ThunkAction => async (dispatch) => {

    try {
        await InvestmentRecosApi.deleteInvestmentReco(id);
        dispatch(removeInvestRecoEntities(InvestRecoApiSchema.InvestmentRecoModelSchema, [id]));
        dispatch(createDeleteInvestmentRecommendationSuccessAction(id));
        return;
    }
    catch (error) {
        logError(error);
        throw error;
    }
};

export const createInvestmentReco = (investmentRecoRequest: InvestmentRecoSaveDto): ThunkAction<Promise<InvestmentRecoModel>> => async (dispatch, getState) => {
    const state = getState();

    if (state?.investmentRecos?.createRequest && state.investmentRecos.createRequest.isSubmitting) {
        const promise = PromiseStore.get<InvestmentRecoModel>('createInvestmentRecoId', investmentRecoRequest);
        if (promise) {
            return promise;
        }
    }

    dispatch(createCreateInvestmentRecommendationAction());

    try {
        let data;
        if (state.investmentRecos.files[0]) {
            const attachedFileId = await FilesApi.createFile(state.investmentRecos.files);
            data = await InvestmentRecosApi.createInvestmentReco({ ...investmentRecoRequest, attachedFileId: attachedFileId });
        }
        else {
            data = await InvestmentRecosApi.createInvestmentReco(investmentRecoRequest);
        }
        const normalizedData = normalize(data, InvestRecoApiSchema.InvestmentRecoModelSchema);
        dispatch(mergeInvestRecoEntities(normalizedData.entities));
        dispatch(createCreateInvestmentRecommendationSuccessAction(investmentRecoRequest, data));
        dispatch(createClearFilesAction());
        return data;
    }
    catch (error) {
        logError(error);
        dispatch(createCreateInvestmentRecommendationFailureAction());
        throw error;
    }
};

export const publishSgmPublication = (draftAttachmentId: number): ThunkAction<Promise<InvestmentRecoResponseModel>> => async (dispatch, getState) => {
    const state = getState();

    if (state?.investmentRecos?.publishFromSgmPubRequest && (state.investmentRecos.publishFromSgmPubRequest?.isFetching || !state.investmentRecos.publishFromSgmPubRequest.didInvalidate)) {
        const promise = PromiseStore.get<InvestmentRecoResponseModel>('publishFromSgmPub', draftAttachmentId);
        if (promise) {
            return promise;
        }
    }

    try {
        const fetchTask = (async () => {
            const data = await InvestmentRecosApi.publishSgmPublication(draftAttachmentId);
            dispatch(createPublishSgmPublicationSuccessAction(draftAttachmentId, data));
            return data;
        })();

        PromiseStore.set(fetchTask, 'publishFromSgmPub', draftAttachmentId);
        dispatch(createPublishSgmPublicationAction(draftAttachmentId));
        return await fetchTask;
    }
    catch (error) {
        dispatch(createPublishSgmPublicationFailureAction(draftAttachmentId));
        logError(error);
        throw error;
    }
};

export const closeProductReco = (id: number, productId: number): ThunkAction => async (dispatch, getState) => {
    const state = getState();

    if (state?.investmentRecos?.closeProductReco && state.investmentRecos.closeProductReco.isClosing) {
        const promise = PromiseStore.get('closeProductReco', productId);
        if (promise) {
            return promise;
        }
    }

    try {
        const fetchTask = (async () => {
            await InvestmentRecosApi.closeProductInvestmentReco(id, productId);
            dispatch(createCloseProductRecoSuccessAction(id));
            return;
        })();

        PromiseStore.set(fetchTask, 'closeProductReco', productId);
        dispatch(createCloseProductRecoAction());
        return await fetchTask;
    }
    catch (error) {
        dispatch(createCloseProductRecoFailureAction());
        logError(error);
        throw error;
    }
};