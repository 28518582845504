import socgenLogoCompactUrl from 'images/socgen_logo_compact.svg';
import sgmWordmark from 'images/sgm_wordmark.svg';
import React from 'react';

export const HeaderLogo = () => {
    return  (
    <>
        <div className="navbar-logo">
            <img src={socgenLogoCompactUrl} alt="SG logo" />
        </div>
        <div className="navbar-title-divider"></div>
        <div className="navbar-service-name">
            <img src={sgmWordmark} alt="SG logo" />
        </div>
    </>
);
};