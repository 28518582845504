export function createOpenModal(name: string) {
    return {
        type: '@MODAL/OPEN_MODAL',
        name,
    } as const;
}

export function createCloseModal(name: string) {
    return {
        type: '@MODAL/CLOSE_MODAL',
        name,
    } as const;
}

export type ModalsActions =
    | ReturnType<typeof createOpenModal>
    | ReturnType<typeof createCloseModal>