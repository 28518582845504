declare global {
    // tslint:disable-next-line:interface-name
    interface Element {
        displayUserConsentPopup(): void;
        getUserConsentOnQumu(): Promise<boolean>;
    }

    interface IConsent {
        category: string;
        name: string;
        consent: boolean;
    }
}

export interface IConsent {
    qumuConsent: boolean;
}

const getMiniFooter = () => {
    return document.getElementById('sgm-mini-footer');
};

export const displayCookieConsents = () => {
    const widget = getMiniFooter();
    return widget?.displayUserConsentPopup?.();
};

export const getUserConsentOnQumu = () => {
    const widget = getMiniFooter();
    return widget?.getUserConsentOnQumu?.() ?? Promise.resolve(false);
};

export const addChangeCookieConsentsEvent = (consentUpdatedCallback: (consent: IConsent) => void) => {
    const updateConsent = async () => {
        const qumuConsent = await getUserConsentOnQumu();

        consentUpdatedCallback({
            qumuConsent,
        });
    };

    document.addEventListener('sgwt-mini-footer--current-user-consent', updateConsent);
    document.addEventListener('sgwt-mini-footer--user-consent-changed', updateConsent);
    return () => {
        document.removeEventListener('sgwt-mini-footer--current-user-consent', updateConsent);
        document.removeEventListener('sgwt-mini-footer--user-consent-changed', updateConsent);
    };
};

let cookieConsentDone = false;

export const setCookieConsentDone = () => {
    cookieConsentDone = true;
};

export const getCookieConsentDone = () => {
    return cookieConsentDone;
};
