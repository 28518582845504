import { format } from 'date-fns';
import * as React from 'react';
import { ToastContentProps } from 'react-toastify';

export type ToastMessageProps = Partial<ToastContentProps> & {
    title?: string;
    message?: string;
};

export const ToastMessage: React.FC<ToastMessageProps> = ({ title, message, closeToast, toastProps }) => {
    let toastTitle = title;
    if (!title) {
        switch (toastProps?.type) {
            case 'error':
                toastTitle = 'Error';
                break;

            case 'warning':
                toastTitle = 'Warning';
                break;

            case 'success':
                toastTitle = 'Success';
                break;

            default:
                toastTitle = 'Information';
                break;
        }
    }

    return (
        <>
            <div className="notification-header" style={{borderStyle: 'none'}}>
                <strong>{toastTitle}</strong>
                <div className="flex-fill" />
                <button type="button" className="ms-4 btn-close rbt-close" data-dismiss="toast" aria-label="Close" onClick={closeToast}/>
            </div>
            {message && <div className="notification-body text-primary">{message}</div>}
            <div className="notification-footer" style={{ borderStyle: 'none' }}>
                <div className="flex-fill" />
                <span className="text-secondary me-3 mb-2">
                    {format(new Date(), 'HH:mm aa')}
                </span>
            </div>
        </>
    );
};
