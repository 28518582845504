import { Reducer } from 'store';
const unloadedState: {
    readonly [name: string]: boolean;
} = {
} as const;

export type AsyncTypeaheadState = typeof unloadedState;

export const AsyncTypeaheadReducer: Reducer<AsyncTypeaheadState> = (state = unloadedState, action) => {
    switch (action.type) {
        case '@ASYNCTYPEAHEAD/OPEN_ASYNCTYPEAHEAD':
            return {
                ...state,
                [action.name]: true,
            };

        case '@ASYNCTYPEAHEAD/CLOSE_ASYNCTYPEAHEAD':
            return {
                ...state,
                [action.name]: false,
            };

        default:
            return state;
    }
};