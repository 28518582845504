import { InvestmentRecoResponseModel, FileParameter, ProductRecoStatus, Recommendation } from 'services/ApiService/InvestReco/InvestRecoApiClient';
import { AccessRight, InvestmentRecoModel, InvestmentRecoSaveDto } from 'services/ApiService/InvestReco/InvestRecoApiClient';

export const createFetchInvestmentRecommendationsAction = (
	skip: number,
	take: number,
	icId?: string,
	identifier?: string,
	instrumentName?: string,
	fromPublicationDate?: Date,
	untilPublicationDate?: Date,
	fromLiveStatusEndDate?: Date,
	untilLiveStatusEndDate?: Date,
	recommendation?: Recommendation,
	status?: ProductRecoStatus) => {
	return {
		type: '@INVESTMENT_RECOS/FETCH_INVESTMENT_RECOS',
		payload: {
			icId,
			identifier,
			instrumentName,
			fromPublicationDate,
			untilPublicationDate,
			fromLiveStatusEndDate,
			untilLiveStatusEndDate,
			recommendation,
			status,
			skip,
			take,
		},
	} as const;
};

export const createFetchInvestmentRecommendationsSuccessAction = (investmentRecosResponse: string[],
	totalPages: number,
	totalResults: number,
	skip: number,
	take: number,
	icId?: string,
	identifier?: string,
	instrumentName?: string,
	fromPublicationDate?: Date,
	untilPublicationDate?: Date,
	fromLiveStatusEndDate?: Date,
	untilLiveStatusEndDate?: Date,
	recommendation?: Recommendation,
	status?: ProductRecoStatus) => {
	return {
		type: '@INVESTMENT_RECOS/FETCH_INVESTMENT_RECOS_SUCCESS',
		payload: {
			icId,
			identifier,
			instrumentName,
			fromPublicationDate,
			untilPublicationDate,
			fromLiveStatusEndDate,
			untilLiveStatusEndDate,
			recommendation,
			status,
			skip,
			take,
			investmentRecosResponse,
			totalPages,
			totalResults,
		},
	} as const;
};

export const createFetchInvestmentRecommendationsFailureAction = (
	skip: number,
	take: number,
	icId?: string,
	identifier?: string,
	instrumentName?: string,
	fromPublicationDate?: Date,
	untilPublicationDate?: Date,
	fromLiveStatusEndDate?: Date,
	untilLiveStatusEndDate?: Date,
	recommendation?: Recommendation,
	status?: ProductRecoStatus) => {
	return {
		type: '@INVESTMENT_RECOS/FETCH_INVESTMENT_RECOS_FAILURE',
		payload: {
			icId,
			identifier,
			instrumentName,
			fromPublicationDate,
			untilPublicationDate,
			fromLiveStatusEndDate,
			untilLiveStatusEndDate,
			recommendation,
			status,
			skip,
			take,
		},
	} as const;
};

export function createSetSearchTermsAction(
	searchTerms: string | null, 
	skip: number, 
	take: number, 
	fromPublicationDate?: Date, 
	untilPublicationDate?: Date, 
	fromLiveStatusEndDate?: Date,
	untilLiveStatusEndDate?: Date,
	icId?: string, 
	instrumentName?: string,
	identifier?: string,
	recommendation?: Recommendation,
	status?: ProductRecoStatus) {
	return {
		type: '@INVESTMENT_RECOS/SET_SEARCH_TERMS',
		searchTerms,
		skip,
		take,
		fromPublicationDate,
		untilPublicationDate,
		fromLiveStatusEndDate,
		untilLiveStatusEndDate,
		icId,
		instrumentName,
		identifier,
		recommendation,
		status,
	} as const;
}

export const createSetFilesAction = (files: FileParameter[]) => {
	return {
		type: '@INVESTMENT_RECOS/SET_FILES',
		payload: {
			files,
		},
	} as const;
};

export const createClearFilesAction = () => {
	return {
		type: '@INVESTMENT_RECOS/CLEAR_FILES',
	} as const;
};

export const createFetchInvestmentRecommendationByIdAction = (id: number) => {
	return {
		type: '@INVESTMENT_RECOS/FETCH_INVESTMENT_RECO_BY_ID',
		payload: {
			id,
		},
	} as const;
};

export const createFetchInvestmentRecommendationByIdSuccessAction = (id: number) => {
	return {
		type: '@INVESTMENT_RECOS/FETCH_INVESTMENT_RECO_BY_ID_SUCCESS',
		payload: {
			id,
		},
	} as const;
};

export const createFetchInvestmentRecommendationByIdFailureAction = (id: number) => {
	return {
		type: '@INVESTMENT_RECOS/FETCH_INVESTMENT_RECO_BY_ID_FAILURE',
		payload: {
			id,
		},
	} as const;
};

export const createFetchUserAccessRightsOnInvestmentRecommendationAction = (id: number) => {
	return {
		type: '@INVESTMENT_RECOS/FETCH_USER_ACCESS_RIGHTS_ON_INVESTMENT_RECO_ID',
		payload: {
			id,
		},
	} as const;
};

export const createFetchUserAccessRightsOnInvestmentRecommendationSuccessAction = (id: number, accessRight: AccessRight[]) => {
	return {
		type: '@INVESTMENT_RECOS/FETCH_USER_ACCESS_RIGHTS_ON_INVESTMENT_RECO_ID_SUCCESS',
		payload: {
			id,
			accessRight,
		},
	} as const;
};

export const createFetchUserAccessRightsOnInvestmentRecommendationFailureAction = (id: number) => {
	return {
		type: '@INVESTMENT_RECOS/FETCH_USER_ACCESS_RIGHTS_ON_INVESTMENT_RECO_ID_FAILURE',
		payload: {
			id,
		},
	} as const;
};

export const createDeleteInvestmentRecommendationSuccessAction = (investRecoId: number) => {
	return {
		type: '@INVESTMENT_RECOS/DELETE_INVESTMENT_RECO_SUCCESS',
		payload: {
			investRecoId,
		},
	} as const;
};

export const createCreateInvestmentRecommendationAction = () => {
	return {
		type: '@INVESTMENT_RECOS/CREATE_INVESTMENT_RECO_ACTION',
	} as const;
};

export const createCreateInvestmentRecommendationSuccessAction = (investRecoTocreate: InvestmentRecoSaveDto, created: InvestmentRecoModel) => {
	return {
		type: '@INVESTMENT_RECOS/CREATE_INVESTMENT_RECO_SUCCESS',
		payload: {
			investRecoTocreate,
			created,
		},
	} as const;
};

export const createCreateInvestmentRecommendationFailureAction = () => {
	return {
		type: '@INVESTMENT_RECOS/CREATE_INVESTMENT_RECO_FAILURE',
	} as const;
};

export const createUpdateInvestmentRecommendationAction = () => {
	return {
		type: '@INVESTMENT_RECOS/UPDATE_INVESTMENT_RECO_ACTION',
	} as const;
};

export const createUpdateInvestmentRecommendationSuccessAction = (id: number) => {
	return {
		type: '@INVESTMENT_RECOS/UPDATE_INVESTMENT_RECO_SUCCESS',
		payload: {
			id,
		},
	} as const;
};

export const createUpdateInvestmentRecommendationFailureAction = () => {
	return {
		type: '@INVESTMENT_RECOS/UPDATE_INVESTMENT_RECO_FAILURE',
	} as const;
};

export const createPublishSgmPublicationAction = (draftAttachmentId: number) => {
	return {
		type: '@INVESTMENT_RECOS/PUBLISH_FROM_SGM_PUB',
		payload: {
			draftAttachmentId,
		},
	} as const;
};

export const createPublishSgmPublicationSuccessAction = (draftAttachmentId: number, createdInvestReco: InvestmentRecoResponseModel) => {
	return {
		type: '@INVESTMENT_RECOS/PUBLISH_FROM_SGM_PUB_SUCCESS',
		payload: {
			draftAttachmentId,
			createdInvestReco,
		},
	} as const;
};

export const createPublishSgmPublicationFailureAction = (draftAttachmentId: number) => {
	return {
		type: '@INVESTMENT_RECOS/PUBLISH_FROM_SGM_PUB_FAILURE',
		payload: {
			draftAttachmentId,
		},
	} as const;
};

export const createCloseProductRecoAction = () => {
	return {
		type: '@INVESTMENT_RECOS/CLOSE_PRODUCT_RECO',
	} as const;
};

export const createCloseProductRecoSuccessAction = (id: number) => {
	return {
		type: '@INVESTMENT_RECOS/CLOSE_PRODUCT_RECO_SUCCESS',
		payload: {
			id,
		},
	} as const;
};

export const createCloseProductRecoFailureAction = () => {
	return {
		type: '@INVESTMENT_RECOS/CLOSE_PRODUCT_RECO_FAILURE',
	} as const;
};

export type InvestmentRecosActions =
	| ReturnType<typeof createFetchInvestmentRecommendationsAction>
	| ReturnType<typeof createFetchInvestmentRecommendationsSuccessAction>
	| ReturnType<typeof createFetchInvestmentRecommendationsFailureAction>
	| ReturnType<typeof createSetSearchTermsAction>
	| ReturnType<typeof createSetFilesAction>
	| ReturnType<typeof createClearFilesAction>
	| ReturnType<typeof createFetchInvestmentRecommendationByIdAction>
	| ReturnType<typeof createFetchInvestmentRecommendationByIdSuccessAction>
	| ReturnType<typeof createFetchInvestmentRecommendationByIdFailureAction>
	| ReturnType<typeof createFetchUserAccessRightsOnInvestmentRecommendationAction>
	| ReturnType<typeof createFetchUserAccessRightsOnInvestmentRecommendationSuccessAction>
	| ReturnType<typeof createFetchUserAccessRightsOnInvestmentRecommendationFailureAction>
	| ReturnType<typeof createUpdateInvestmentRecommendationAction>
	| ReturnType<typeof createUpdateInvestmentRecommendationSuccessAction>
	| ReturnType<typeof createUpdateInvestmentRecommendationFailureAction>
	| ReturnType<typeof createCreateInvestmentRecommendationAction>
	| ReturnType<typeof createCreateInvestmentRecommendationSuccessAction>
	| ReturnType<typeof createCreateInvestmentRecommendationFailureAction>
	| ReturnType<typeof createDeleteInvestmentRecommendationSuccessAction>
	| ReturnType<typeof createPublishSgmPublicationAction>
	| ReturnType<typeof createPublishSgmPublicationSuccessAction>
	| ReturnType<typeof createPublishSgmPublicationFailureAction>
	| ReturnType<typeof createCloseProductRecoAction>
	| ReturnType<typeof createCloseProductRecoSuccessAction>
	| ReturnType<typeof createCloseProductRecoFailureAction>