import { StrategyModel } from 'services/ApiService/InvestReco/InvestRecoApiClient';
import { Reducer } from 'store';

export const initialState = {
    strategies: {
        isFetching: false as boolean,
        didInvalidate: true as boolean,
        data: undefined as StrategyModel[] | null | undefined,
    },
} as const;

export type StrategiesState = typeof initialState;

export const StrategiesReducer: Reducer<StrategiesState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case '@STRATEGIES/FETCH_STRATEGIES':
            return {
                ...state,
                strategies: {
                    ...state.strategies,
                    isFetching: true,
                },
            };
        case '@STRATEGIES/FETCH_STRATEGIES_SUCCESS':
            return {
                ...state,
                strategies: {
                    ...state.strategies,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.strategies,
                },
            };
        case '@STRATEGIES/FETCH_STRATEGIES_FAILURE':
            return {
                ...state,
                strategies: {
                    ...state.strategies,
                    isFetching: false,
                    didInvalidate: true,
                },
            };
        default:
            return state;
    }
};