import { Reducer } from 'store';

export const initialState = {
    environment: undefined as string | undefined | null,
    loggedUserIcid: undefined as string | undefined | null,
    privileges: [] as string[],
    isFetching: false as boolean,
    didInvalidate: true as boolean,
} as const;

export type AppContextState = typeof initialState;

export const AppContextReducer: Reducer<AppContextState> = (state = initialState, action) => {
    switch (action.type) {
        case '@APP_CONTEXT/FETCH_APP_CONTEXT':
            return {
                ...state,
                didInvalidate: true,
                isFetching: true,
            };

        case '@APP_CONTEXT/FETCH_APP_CONTEXT_SUCCESS':
            return {
                ...state,
                didInvalidate: false,
                isFetching: false,
                environment: action.payload.environment,
                loggedUserIcid: action.payload.loggedUserIcid,
                privileges: action.payload.privileges || [],

            };

        case '@APP_CONTEXT/FETCH_APP_CONTEXT_FAILURE':
            return {
                ...state,
                didInvalidate: true,
                isFetching: false,
            };

        case '@APP_CONTEXT/SIGN_OUT':
            return {
                ...state,
                didInvalidate: true,
                isFetching: false,
                loggedUserIcid: undefined,
            };

        default:
            return state;
    }
};
