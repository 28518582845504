import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'react-app-polyfill/ie11';

import { configureRum } from 'config/rum';
import { AppProvider } from 'components/common/AppProvider';
import { ErrorBoundary } from 'components/common/ErrorBoundary';
import { ScrollToTop } from 'components/common/ScrollToTop';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { initSGConnect } from 'services/SgConnect';
import { AppRoutes } from 'routes';
import { MaterialUiProvider } from 'components/common/MaterialUiProvider';

import './css/bootstrapTheming.scss';
import './css/site.scss';

async function start() {
    const App = (children: React.ReactNode) => (
        <>
            <AppProvider>
                <MaterialUiProvider>
                    <ErrorBoundary>
                        <BrowserRouter>
                            <ScrollToTop />
                                {children}
                        </BrowserRouter>
                    </ErrorBoundary>
                </MaterialUiProvider>
            </AppProvider>
        </>
    );

    const renderApp = () => {
        const container = document.getElementById('root');

         
        const root = createRoot(container!);
        root.render(App((<AppRoutes />)));
        const appInitLoader = document.getElementById('app-init-loader');
        if (appInitLoader) {
            appInitLoader.remove();
        }
    };

    initSGConnect();
    configureRum();
    renderApp();
}

start();
