import { normalize } from 'normalizr';
import { ThunkAction } from 'components/common/AppProvider';
import { PersonsApi, InvestRecoApiSchema } from 'services/ApiService';
import { logError } from 'services/Logger';
import { PromiseStore } from 'services/PromiseStore';
import {
    createFetchAllAnalystsAction,
    createFetchAllAnalystsFailureAction,
    createFetchAllAnalystsSuccessAction,
    createFetchAllMad2MarAnalystsAction,
    createFetchAllMad2MarAnalystsFailureAction,
    createFetchAllMad2MarAnalystsSuccessAction,
    createFetchAnalystsAction,
    createFetchAnalystsFailureAction,
    createFetchAnalystsSuccessAction,
    createFetchNavigateAsPersonsAction,
    createFetchNavigateAsPersonsFailureAction,
    createFetchNavigateAsPersonsSuccessAction,
} from './PersonsActions';
import { mergeInvestRecoEntities } from 'store/Normalizr/NormalizrAction';
import { Analyst, Person } from 'services/ApiService/InvestReco/InvestRecoApiClient';

export const getAnalysts = (icIds: string[]): ThunkAction => async (dispatch, getState) => {
    const state = getState();

    if (icIds.length == 0) {
        return;
    }

    const distinctIcIds = [...new Set(icIds.filter(id => !!id))];
    const missingIcIds = distinctIcIds
        .filter(icId => {
            const analystRequest = state.persons.analysts[icId];
            return !analystRequest || !analystRequest.isFetching && analystRequest.didInvalidate;
        });

    const fetchingAnalystsTask = distinctIcIds
        .filter(icId => !missingIcIds.includes(icId))
        .map(icId => PromiseStore.get<Analyst[]>('getAnalyst', icId)
            || Promise.resolve([]));

    if (!missingIcIds.length && icIds.length) {
        await Promise.all(fetchingAnalystsTask);
        return;
    }

    try {
        const fetchTask = (async () => {
            const data = await PersonsApi
                .getAnalysts(missingIcIds);
            const normalizedData = normalize(data.analysts, InvestRecoApiSchema.AnalystSchemaArray);
            dispatch(mergeInvestRecoEntities(normalizedData.entities));
            dispatch(createFetchAnalystsSuccessAction(normalizedData.result));
            return;
        })();

        for (const icId of missingIcIds) {
            PromiseStore.set(fetchTask, 'getAuthor', icId);
        }

        dispatch(createFetchAnalystsAction(missingIcIds));

        await Promise.all([fetchTask, ...fetchingAnalystsTask]);

        return fetchTask;
    }
    catch (error) {
        dispatch(createFetchAnalystsFailureAction(missingIcIds));
        logError(error);
        throw error;
    }
};
export const getAllAnalysts = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();

    if (state.persons.isFetchingAll || !state.persons.didInvalidateAll) {
        const promise = PromiseStore.get('getAllAnalysts');
        if (promise) {
            return promise;
        }
    }

    dispatch(createFetchAllAnalystsAction());

    const fetchTask = PersonsApi
        .getAnalysts()
        .then((data) => {
            const normalizedData = normalize(data.analysts, InvestRecoApiSchema.AnalystSchemaArray);
            dispatch(mergeInvestRecoEntities(normalizedData.entities));
            dispatch(createFetchAllAnalystsSuccessAction(normalizedData.result));
        })
        .catch((error) => {
            dispatch(createFetchAllAnalystsFailureAction());
            logError(error);
            throw error;
        });

    PromiseStore.set(fetchTask, 'getAllAnalysts');

    return fetchTask;
};

export const getAllMad2MarAnalysts = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();

    if (state.persons.isFetchingAllMad2Mar || !state.persons.didInvalidateAllMad2Mar) {
        const promise = PromiseStore.get('getAllMad2MarAnalysts');
        if (promise) {
            return promise;
        }
    }

    dispatch(createFetchAllMad2MarAnalystsAction());

    const fetchTask = PersonsApi
        .getAnalysts(undefined, true)
        .then((data) => {
            const normalizedData = normalize(data.analysts, InvestRecoApiSchema.AnalystSchemaArray);
            dispatch(mergeInvestRecoEntities(normalizedData.entities));
            dispatch(createFetchAllMad2MarAnalystsSuccessAction(normalizedData.result));
        })
        .catch((error) => {
            dispatch(createFetchAllMad2MarAnalystsFailureAction());
            logError(error);
            throw error;
        });

    PromiseStore.set(fetchTask, 'getAllMad2MarAnalysts');

    return fetchTask;
};

export const getNavigateAsPersons = (): ThunkAction<Promise<Person[]>> => async (dispatch, getState) => {
    const state = getState();
    if (state.persons.navigateAs.isFetching || state.persons.navigateAs.data) {
        const promise = PromiseStore.get<Person[]>('getNavigateAsPersons');
        if (promise) {
            return await promise;
        }
    }

    try {
        const fetchTask = (async () => {
            const data = await PersonsApi.getNavigateAsPersons();
            const normalizedData = normalize(data.persons, InvestRecoApiSchema.PersonSchemaArray);
            dispatch(mergeInvestRecoEntities(normalizedData.entities));
            dispatch(createFetchNavigateAsPersonsSuccessAction(normalizedData.result));
            return data.persons || [];
        })();

        PromiseStore.set(fetchTask, 'getNavigateAsPersons');

        dispatch(createFetchNavigateAsPersonsAction());

        return await fetchTask;
    } catch (error) {
        dispatch(createFetchNavigateAsPersonsFailureAction());
        logError(error);
        throw error;
    }
};