import { Reducer } from 'store';
const unloadedState: {
    readonly [name: string]: string;
} = {
} as const;

export type ModalState = typeof unloadedState;

export const ModalsReducer: Reducer<ModalState> = (state = unloadedState, action) => {
    switch (action.type) {
        case '@MODAL/OPEN_MODAL':
            return {
                ...state,
                [action.name]: 'block',
            };

        case '@MODAL/CLOSE_MODAL':
            return {
                ...state,
                [action.name]: 'none',
            };

        default:
            return state;
    }
};