import { AnalyticsApi } from 'services/ApiService';
import { PromiseStore } from 'services/PromiseStore';
import { ThunkAction } from 'components/common/AppProvider';
import { logError } from 'services/Logger';
import {
    createFetchInstrumentAction,
    createFetchInstrumentFailureAction,
    createFetchInstrumentSuccessAction,
    createSearchInstrumentsByCodeAction,
    createSearchInstrumentsByCodeFailureAction,
    createSearchInstrumentsByCodeSuccessAction,
    createFetchMarketCodesAction,
    createFetchMarketCodesFailureAction,
    createFetchMarketCodesSuccessAction,
} from './AnalyticsActions';
import { getInstrumentKey } from 'services/InvestmentRecosService';
import { IdentifierType } from 'services/InstrumentsService';
import { InvestmentRecoInstrumentsApi } from 'services/ApiService/InvestReco/InvestRecoApi';
import { InstrumentModel } from 'services/ApiService/InvestReco/InvestRecoApiClient';

export interface MarketCodes {
    bloomberg?: string,
    ric?: string,
    isin?: string,
    sedol?: string,
    description?: string
}

export const getBloombergMarketCodes = (query: string): ThunkAction => (dispatch, getState) => {
    const state = getState();

    if (state.analytics.marketCodes.isFetching || !state.analytics.marketCodes.didInvalidate) {
        const promise = PromiseStore.get('getBloombergMarketCodes', query);
        if (promise) {
            return promise;
        }
    }

    dispatch(createFetchMarketCodesAction(query));

    const fetchTask = AnalyticsApi
        .searchBloombergMarketCodes(query)
        .then((data) => {
            dispatch(createFetchMarketCodesSuccessAction(query, data.marketCodes ?? []));
        })
        .catch((error) => {
            dispatch(createFetchMarketCodesFailureAction(query));
            logError(error);
            throw error;
        });

    PromiseStore.set(fetchTask, 'getBloombergMarketCodes');

    return fetchTask;
};

export const getRicMarketCodes = (query: string): ThunkAction => (dispatch, getState) => {
    const state = getState();

    if (state.analytics.marketCodes.isFetching || !state.analytics.marketCodes.didInvalidate) {
        const promise = PromiseStore.get('getRicMarketCodes', query);
        if (promise) {
            return promise;
        }
    }

    dispatch(createFetchMarketCodesAction(query));

    const fetchTask = AnalyticsApi
        .searchRicMarketCodes(query)
        .then((data) => {
            dispatch(createFetchMarketCodesSuccessAction(query, data.marketCodes ?? []));
        })
        .catch((error) => {
            dispatch(createFetchMarketCodesFailureAction(query));
            logError(error);
            throw error;
        });

    PromiseStore.set(fetchTask, 'getRicMarketCodes');

    return fetchTask;
};

export const getSedolMarketCodes = (query: string): ThunkAction => (dispatch, getState) => {
    const state = getState();

    if (state.analytics.marketCodes.isFetching || !state.analytics.marketCodes.didInvalidate) {
        const promise = PromiseStore.get('getSedolMarketCodes', query);
        if (promise) {
            return promise;
        }
    }

    dispatch(createFetchMarketCodesAction(query));

    const fetchTask = AnalyticsApi
        .searchSedolMarketCodes(query)
        .then((data) => {
            dispatch(createFetchMarketCodesSuccessAction(query, data.marketCodes ?? []));
        })
        .catch((error) => {
            dispatch(createFetchMarketCodesFailureAction(query));
            logError(error);
            throw error;
        });

    PromiseStore.set(fetchTask, 'getSedolMarketCodes');

    return fetchTask;
};

export const getIsinMarketCodes = (query: string): ThunkAction => (dispatch, getState) => {
    const state = getState();

    if (state.analytics.marketCodes.isFetching || !state.analytics.marketCodes.didInvalidate) {
        const promise = PromiseStore.get('getIsinMarketCodes', query);
        if (promise) {
            return promise;
        }
    }

    dispatch(createFetchMarketCodesAction(query));

    const fetchTask = AnalyticsApi
        .searchIsinMarketCodes(query)
        .then((data) => {
            dispatch(createFetchMarketCodesSuccessAction(query, data.marketCodes ?? []));
        })
        .catch((error) => {
            dispatch(createFetchMarketCodesFailureAction(query));
            logError(error);
            throw error;
        });

    PromiseStore.set(fetchTask, 'getIsinMarketCodes');

    return fetchTask;
};

export const getDescriptionMarketCodes = (query: string): ThunkAction => (dispatch, getState) => {
    const state = getState();

    if (state.analytics.marketCodes.isFetching || !state.analytics.marketCodes.didInvalidate) {
        const promise = PromiseStore.get('getDescriptionMarketCodes', query);
        if (promise) {
            return promise;
        }
    }

    dispatch(createFetchMarketCodesAction(query));

    const fetchTask = AnalyticsApi
        .searchDescription(query)
        .then((data) => {
            dispatch(createFetchMarketCodesSuccessAction(query, data.marketCodes ?? []));
        })
        .catch((error) => {
            dispatch(createFetchMarketCodesFailureAction(query));
            logError(error);
            throw error;
        });

    PromiseStore.set(fetchTask, 'getDescriptionMarketCodes');

    return fetchTask;
};

export const getInstrumentsByBloomberg = (query: string): ThunkAction => (dispatch, getState) => {
    const state = getState();

    if (state.analytics.instrumentsByCodes.isFetching || !state.analytics.instrumentsByCodes.didInvalidate) {
        const promise = PromiseStore.get('getInstrumentsByBloomberg', query);
        if (promise) {
            return promise;
        }
    }

    dispatch(createSearchInstrumentsByCodeAction(query));

    const fetchTask = AnalyticsApi
        .searchInstrumentsByBloomberg(query)
        .then((data) => {
            dispatch(createSearchInstrumentsByCodeSuccessAction(query, data.instruments ?? []));
        })
        .catch((error) => {
            dispatch(createSearchInstrumentsByCodeFailureAction(query));
            logError(error);
            throw error;
        });

    PromiseStore.set(fetchTask, 'getInstrumentsByBloomberg');

    return fetchTask;
};

export const getInstrumentsByRic = (query: string): ThunkAction => (dispatch, getState) => {
    const state = getState();

    if (state.analytics.instrumentsByCodes.isFetching || !state.analytics.instrumentsByCodes.didInvalidate) {
        const promise = PromiseStore.get('getInstrumentsByRic', query);
        if (promise) {
            return promise;
        }
    }

    dispatch(createSearchInstrumentsByCodeAction(query));

    const fetchTask = AnalyticsApi
        .searchInstrumentsByRic(query)
        .then((data) => {
            dispatch(createSearchInstrumentsByCodeSuccessAction(query, data.instruments ?? []));
        })
        .catch((error) => {
            dispatch(createSearchInstrumentsByCodeFailureAction(query));
            logError(error);
            throw error;
        });

    PromiseStore.set(fetchTask, 'getInstrumentsByRic');

    return fetchTask;
};

export const getInstrumentsBySedol = (query: string): ThunkAction => (dispatch, getState) => {
    const state = getState();

    if (state.analytics.instrumentsByCodes.isFetching || !state.analytics.instrumentsByCodes.didInvalidate) {
        const promise = PromiseStore.get('getInstrumentsBySedol', query);
        if (promise) {
            return promise;
        }
    }

    dispatch(createSearchInstrumentsByCodeAction(query));

    const fetchTask = AnalyticsApi
        .searchInstrumentsBySedol(query)
        .then((data) => {
            dispatch(createSearchInstrumentsByCodeSuccessAction(query, data.instruments ?? []));
        })
        .catch((error) => {
            dispatch(createSearchInstrumentsByCodeFailureAction(query));
            logError(error);
            throw error;
        });

    PromiseStore.set(fetchTask, 'getInstrumentsBySedol');

    return fetchTask;
};

export const getInstrumentsByIsin = (query: string): ThunkAction => (dispatch, getState) => {
    const state = getState();

    if (state.analytics.instrumentsByCodes.isFetching || !state.analytics.instrumentsByCodes.didInvalidate) {
        const promise = PromiseStore.get('getInstrumentsByIsin', query);
        if (promise) {
            return promise;
        }
    }

    dispatch(createSearchInstrumentsByCodeAction(query));

    const fetchTask = AnalyticsApi
        .searchInstrumentsByIsin(query)
        .then((data) => {
            dispatch(createSearchInstrumentsByCodeSuccessAction(query, data.instruments ?? []));
        })
        .catch((error) => {
            dispatch(createSearchInstrumentsByCodeFailureAction(query));
            logError(error);
            throw error;
        });

    PromiseStore.set(fetchTask, 'getInstrumentsByIsin');

    return fetchTask;
};

export const getInstrumentsByDescription = (query: string): ThunkAction => (dispatch, getState) => {
    const state = getState();

    if (state.analytics.instrumentsByCodes.isFetching || !state.analytics.instrumentsByCodes.didInvalidate) {
        const promise = PromiseStore.get('getInstrumentsByDescription', query);
        if (promise) {
            return promise;
        }
    }

    dispatch(createSearchInstrumentsByCodeAction(query));

    const fetchTask = AnalyticsApi
        .searchInstrumentsByDescription(query)
        .then((data) => {
            dispatch(createSearchInstrumentsByCodeSuccessAction(query, data.instruments ?? []));
        })
        .catch((error) => {
            dispatch(createSearchInstrumentsByCodeFailureAction(query));
            logError(error);
            throw error;
        });

    PromiseStore.set(fetchTask, 'getInstrumentsByDescription');

    return fetchTask;
};

export const getInstrument = (codes: MarketCodes): ThunkAction<Promise<InstrumentModel>> => async (dispatch, getState) => {
    const state = getState();
    const { bloomberg, ric, isin, sedol, description } = codes;
    const listIdentifier = [bloomberg, ric, isin, sedol, description];

    for (let index = 0; index < listIdentifier.length; index++) {
        if (listIdentifier[index]) {
            const instrumentKey = getInstrumentKey(Object.values(IdentifierType)[index], listIdentifier[index]);
            if (state.analytics.instruments[instrumentKey] && (state.analytics.instruments[instrumentKey].isFetching || !state.analytics.instruments[instrumentKey].didInvalidate)) {
                const promise = PromiseStore.get<InstrumentModel>('getInstrument', instrumentKey);
                if (promise) {
                    return await promise;
                }
            }
            dispatch(createFetchInstrumentAction(listIdentifier[index], Object.values(IdentifierType)[index]));
        }
    }

    const fetchTask = InvestmentRecoInstrumentsApi
        .getInstruments(bloomberg, ric, isin, sedol, description)
        .then((data) => {
            for (let index = 0; index < listIdentifier.length; index++) {
                if (listIdentifier[index]) {
                    dispatch(createFetchInstrumentSuccessAction(listIdentifier[index], Object.values(IdentifierType)[index], data));
                }
            }
            return data;
        })
        .catch((error) => {
            for (let index = 0; index < listIdentifier.length; index++) {
                if (listIdentifier[index]) {
                    dispatch(createFetchInstrumentFailureAction(listIdentifier[index], Object.values(IdentifierType)[index]));
                }
            }
            logError(error);
            throw error;
        });

    for (let index = 0; index < listIdentifier.length; index++) {
        if (listIdentifier[index]) {
            PromiseStore.set(fetchTask, 'getInstrument', getInstrumentKey(Object.values(IdentifierType)[index], listIdentifier[index]));
        }
    }
   
    return await fetchTask;
};
