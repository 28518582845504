import { Reducer } from 'store';
import { CurrencyModel } from 'services/ApiService/InvestReco/InvestRecoApiClient';

const unloadedState = {
    isFetchingAll: false as boolean,
    didInvalidateAll: true as boolean,
    currencies: {} as { [key: string]: CurrencyModel; },
} as const;

export type CurrenciesState = typeof unloadedState;

export const CurrenciesReducer: Reducer<CurrenciesState> = (state = unloadedState, action) => {
    switch (action.type) {
        case '@CURRENCIES/GET_CURRENCIES_REQUEST':
            return {
                ...state,
                isFetchingAll: true,
            };

        case '@CURRENCIES/GET_CURRENCIES_SUCCESS':
            return {
                ...state,
                didInvalidateAll: false,
                isFetchingAll: false,
                currencies: action.currencies,
            };

        case '@CURRENCIES/GET_CURRENCIES_FAILURE':
            return {
                ...state,
                didInvalidateAll: true,
                isFetchingAll: false,
            };

        default:
            return state;
    }
};
