import { ThunkDispatch } from 'components/common/AppProvider';
import { getInstrumentsByBloomberg, getInstrumentsByRic, getInstrumentsByIsin, getInstrumentsBySedol } from 'store/Analytics/AnalyticsThunk';
import { Action, State } from 'store/configureStore';
import { includes } from 'lodash-es';
import { InstrumentModel } from './ApiService/InvestReco/InvestRecoApiClient';

export enum IdentifierType {
    Bloomberg = 'Bloomberg',
    RIC = 'RIC',
    ISIN = 'ISIN',
    SEDOL = 'SEDOL',
}

export const getInstrumentByIdentifier = (identifierType: IdentifierType, identifierSearch: string, dispatch: ThunkDispatch<State, Action>) => {
    switch (identifierType) {
        case IdentifierType.Bloomberg:
            dispatch(getInstrumentsByBloomberg(identifierSearch));
            break;
        case IdentifierType.RIC:
            dispatch(getInstrumentsByRic(identifierSearch));
            break;
        case IdentifierType.ISIN:
            dispatch(getInstrumentsByIsin(identifierSearch));
            break;
        case IdentifierType.SEDOL:
            dispatch(getInstrumentsBySedol(identifierSearch));
            break;
        default:
            break;
    }
};

export const getInstrumentCode = (identifierType: IdentifierType, instrument: InstrumentModel) => {
    switch (identifierType) {
        case IdentifierType.Bloomberg:
            return instrument.bloombergCode ?? '';
        case IdentifierType.ISIN:
            return instrument.isinCode ?? '';
        case IdentifierType.RIC:
            return instrument.ricCode ?? '';
        case IdentifierType.SEDOL:
            return instrument.sedolCode ?? '';
    }
};

export const isInstrumentSelected = (instrument: InstrumentModel, selected: InstrumentModel[]) => {
    return isCodeSelected(instrument.bloombergCode, selected.map(instru => instru.bloombergCode))
        && isCodeSelected(instrument.ricCode, selected.map(instru => instru.ricCode))
        && isCodeSelected(instrument.sedolCode, selected.map(instru => instru.sedolCode))
        && isCodeSelected(instrument.isinCode, selected.map(instru => instru.isinCode))
        && isCodeSelected(instrument.alias, selected.map(instru => instru.alias));
};

export const isCodeSelected = (code: string | undefined, selected: (string | undefined)[]) => {
    return code ? includes(selected, code) : true;
};

export const displayIdentifier = (instrument: InstrumentModel | undefined) => {
    if (!instrument){
        return;
    }
    if (instrument.bloombergCode && instrument.bloombergCode.length > 0) {
        return {identifierType: IdentifierType.Bloomberg, identifier: instrument.bloombergCode};
    }
    if (instrument.sedolCode && instrument.sedolCode.length > 0) {
        return { identifierType: IdentifierType.SEDOL, identifier: instrument.sedolCode };
    }
    if (instrument.ricCode && instrument.ricCode.length > 0) {
        return { identifierType: IdentifierType.RIC, identifier: instrument.ricCode };
    }
    if (instrument.isinCode && instrument.isinCode.length > 0) {
        return { identifierType: IdentifierType.ISIN, identifier: instrument.isinCode };
    }
};