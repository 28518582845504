import {
    AuthorModel,
    AuthorIcIdModel,
    InvestmentRecoModel,
    Analyst,
    ProductModel,
    ProductRecoModel,
    PublicationInformationModel,
    ThirdModel,
    Person,
    LoggedUser,
    CacheInfo,
} from './InvestRecoApiClient';
import { ObjectsWithoutIdProperty, ObjectsWithoutIdPropertyEnum } from './InvestRecoApiSchema';

export class BaseInvestRecoApiSchema {
    protected static getId(value: ObjectsWithoutIdProperty, type: ObjectsWithoutIdPropertyEnum): string {
        switch (type) {
            case ObjectsWithoutIdPropertyEnum.Analyst:
                return (value as Analyst).icId;
            case ObjectsWithoutIdPropertyEnum.AuthorIcIdModel:
                return (value as AuthorIcIdModel).icId || '';
            case ObjectsWithoutIdPropertyEnum.AuthorModel:
                return (value as AuthorModel).icId || '';
            case ObjectsWithoutIdPropertyEnum.InvestmentRecoModel:
                return (value as InvestmentRecoModel).investmentRecoHeadId?.toString() || '';
            case ObjectsWithoutIdPropertyEnum.LoggedUser:
                return (value as LoggedUser).id || '';
            case ObjectsWithoutIdPropertyEnum.Person:
                return (value as Person).icId;
            case ObjectsWithoutIdPropertyEnum.ProductModel:
                return (value as ProductModel).productId.toString() || '';
            case ObjectsWithoutIdPropertyEnum.ProductRecoModel:
                return (value as ProductRecoModel).investmentRecoHeadId.toString() + '_' + (value as ProductRecoModel).productId.toString() || '';
            case ObjectsWithoutIdPropertyEnum.PublicationInformationModel:
                return (value as PublicationInformationModel).attachmentId.toString() || '';
            case ObjectsWithoutIdPropertyEnum.ThirdModel:
                return (value as ThirdModel).thirdId.toString() || '';
            case ObjectsWithoutIdPropertyEnum.CacheInfo:
                return (value as CacheInfo).key || '';

            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const exhaustiveCheck: never = type;
        }
        return '';
    }
}
