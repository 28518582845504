import { Reducer, ApiRequest } from 'store';
import { PublicationInformationModel } from 'services/ApiService/InvestReco/InvestRecoApiClient';

const unloadedState = {
    isFetching: false as boolean,
    didInvalidate: true as boolean,
    data: undefined,
} as ApiRequest<PublicationInformationModel>;

export type AttachmentsState = ApiRequest<PublicationInformationModel>;

export const AttachmentsReducer: Reducer<AttachmentsState> = (state = unloadedState, action) => {
    switch (action.type) {
        case '@ATTACHMENTS/GET_ATTACHMENT_REQUEST':
            return {
                ...state,
                isFetching: true,
            };

        case '@ATTACHMENTS/GET_ATTACHMENT_SUCCESS':
            return {
                ...state,
                didInvalidate: false,
                isFetching: false,
                data: action.payload.attachment,
            };

        case '@ATTACHMENTS/GET_ATTACHMENT_FAILURE':
            return {
                ...state,
                didInvalidate: true,
                isFetching: false,
            };
            
        default:
            return state;
    }
};
