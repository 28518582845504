import { ApiRequest, Reducer } from 'store';

export interface IAnalystsRequestMap {
    readonly [icId: string]: ApiRequest;
}
export const initialState = {
    analysts: {} as IAnalystsRequestMap,
    mad2MarAnalysts: {} as IAnalystsRequestMap,
    isFetchingAll: false as boolean,
    didInvalidateAll: true as boolean,
    isFetchingAllMad2Mar: false as boolean,
    didInvalidateAllMad2Mar: true as boolean,
    navigateAs: {
        data: undefined,
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<string[]>,
} as const;

export type PersonsState = typeof initialState;

export const PersonsReducer: Reducer<PersonsState> = (state = initialState, action) => {
    switch (action.type) {
        case '@ANALYSTS/FETCH_ANALYSTS':
            return {
                ...state,
                analysts: createFetchAnalystsState(
                    state,
                    action.payload.icIds,
                    {
                        isFetching: true,
                        didInvalidate: true,
                    }),
            };

        case '@ANALYSTS/FETCH_ANALYSTS_SUCCESS':
            return {
                ...state,
                analysts: createFetchAnalystsState(
                    state,
                    action.payload.icIds,
                    {
                        isFetching: false,
                        didInvalidate: false,
                    }),
            };

        case '@ANALYSTS/FETCH_ANALYSTS_FAILURE':
            return {
                ...state,
                analysts: createFetchAnalystsState(
                    state,
                    action.payload.icIds,
                    {
                        isFetching: false,
                        didInvalidate: true,
                    }),
            };
        case '@ANALYSTS/FETCH_ALL_ANALYSTS':
            return {
                ...state,
                isFetchingAll: true,
            };

        case '@ANALYSTS/FETCH_ALL_ANALYSTS_SUCCESS':
            return {
                ...state,
                analysts: createFetchAnalystsState(
                    state,
                    action.payload.icIds,
                    {
                        isFetching: false,
                        didInvalidate: false,
                    }),
                isFetchingAll: false,
                didInvalidateAll: false,
            };

        case '@ANALYSTS/FETCH_ALL_ANALYSTS_FAILURE':
            return {
                ...state,
                isFetchingAll: false,
                didInvalidateAll: true,
            };

        case '@ANALYSTS/FETCH_ALL_MAD2MAR_ANALYSTS':
            return {
                ...state,
                isFetchingAllMad2Mar: true,
            };

        case '@ANALYSTS/FETCH_ALL_MAD2MAR_ANALYSTS_SUCCESS':
            return {
                ...state,
                mad2MarAnalysts: createFetchMad2MarAnalystsState(
                    state,
                    action.payload.icIds,
                    {
                        isFetching: false,
                        didInvalidate: false,
                    }),
                analysts: createFetchAnalystsState(
                    state,
                    action.payload.icIds,
                    {
                        isFetching: false,
                        didInvalidate: false,
                    }),
                isFetchingAllMad2Mar: false,
                didInvalidateAllMad2Mar: false,
            };

        case '@ANALYSTS/FETCH_ALL_MAD2MAR_ANALYSTS_FAILURE':
            return {
                ...state,
                isFetchingAllMad2Mar: false,
                didInvalidateAllMad2Mar: true,
            };

        case '@PERSONS/FETCH_NAVIGATE_AS_PERSONS':
            return {
                ...state,
                navigateAs: {
                    ...state.navigateAs,
                    isFetching: true,
                },
            };

        case '@PERSONS/FETCH_NAVIGATE_AS_PERSONS_SUCCESS':
            return {
                ...state,
                navigateAs: {
                    ...state.navigateAs,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.icIds,
                },
            };

        case '@PERSONS/FETCH_NAVIGATE_AS_PERSONS_FAILURE':
            return {
                ...state,
                navigateAs: {
                    ...state.navigateAs,
                    isFetching: false,
                    didInvalidate: true,
                },
            };
        default:
            return state;
    }
};

const createFetchAnalystsState = (state: PersonsState, icIds: string[], apiRequest: ApiRequest) => {
    let stateAnalysts = {
        ...state.analysts,
    };
    for (const icId of icIds) {
        stateAnalysts = {
            ...stateAnalysts,
            [icId]: {
                ...apiRequest,
            },
        };
    }
    return stateAnalysts as IAnalystsRequestMap;
};

const createFetchMad2MarAnalystsState = (state: PersonsState, icIds: string[], apiRequest: ApiRequest) => {
    let stateAnalysts = {
        ...state.mad2MarAnalysts,
    };
    for (const icId of icIds) {
        stateAnalysts = {
            ...stateAnalysts,
            [icId]: {
                ...apiRequest,
            },
        };
    }
    return stateAnalysts as IAnalystsRequestMap;
};