import { forOwn } from 'lodash-es';
import { Reducer } from 'store';
import {
    IInvestRecoEntities,
} from 'services/ApiService';

export const initialState = {
    investReco: {} as IInvestRecoEntities,
} as const;

export type NormalizrState = typeof initialState;

export const NormalizrReducer: Reducer<NormalizrState> = (state = initialState, action) => {
    let newState: any;
    switch (action.type) {
        case '@NORMALIZR/MERGE_INVESTRECO_ENTITIES':
            newState = mergeEntities(state.investReco, action.payload);
            return {
                ...state,
                investReco: newState,
            };

        case '@NORMALIZR/REMOVE_INVESTRECO_ENTITIES':
            newState = removeEntities(state.investReco, action.payload);
            return {
                ...state,
                investReco: newState,
            };




        default:
            return state;
    }
};

type Entities = IInvestRecoEntities;

const removeEntities = (entities: Entities, payload: Entities) => {
    const newState = createNewState(entities);

    forOwn(payload, (value, key) => {
        if (newState[key]) {
            forOwn(value, (_, entityKey) => {
                if (entityKey in newState[key]) {
                    delete newState[key][entityKey];
                }
            });
        }
    });
    return newState;
};

const mergeEntities = (entities: Entities, payload: Entities) => {
    const newState = createNewState(entities);

    forOwn(payload, (value, key) => {
        if (newState[key]) {
            forOwn(value, (entity, entityKey) => {
                newState[key][entityKey] = entity;
            });
        }
        else {
            newState[key] = {
                ...value,
            };
        }
    });
    return newState;
};

const createNewState = (state: Entities) => {
    const newState: any = {};
    forOwn(state, (value, key) => {
        newState[key] = {
            ...value,
        };
    });
    return newState;
};
