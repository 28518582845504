import { ProductRecoModel, ProductRecoStatus, Recommendation, TimeHorizon } from './ApiService/InvestReco/InvestRecoApiClient';

export const getInvestmentRecosKey = (
	icId: string | undefined,
	fromPublicationDate: Date | undefined,
	untilPublicationDate: Date | undefined,
	fromLiveStatusEndDate: Date | undefined,
	untilLiveStatusEndDate: Date | undefined,
	includePrivate: boolean | undefined,
	skip: number | undefined,
	take: number | undefined,
	editableOnly: boolean | undefined,
	instrumentName: string | undefined,
	identifier: string | undefined,
	recommendation: Recommendation | undefined,
	status: ProductRecoStatus | undefined) => {
	return `${toClean(icId)}\
				${toClean(fromPublicationDate)}\
				${toClean(untilPublicationDate)}\
				${toClean(fromLiveStatusEndDate)}\
				${toClean(untilLiveStatusEndDate)}\
				${toClean(includePrivate)}\
				${toClean(skip)}\
				${toClean(take)}\
				${toClean(editableOnly)}\
				${toClean(instrumentName)}\
				${toClean(identifier)}\
				${toClean(recommendation)}\
				${toClean(status)}`.replace(/(\s\s+|[\t\n])/g, '').trim();
};

export const getInstrumentKey = (identifierType: string, identifier: string | undefined) => {
	return `${toClean(identifierType)}${toClean(identifier)}`.replace(/(\s\s+|[\t\n])/g, '').trim();
};

const toClean = (arg: number | string | Date | boolean | TimeHorizon | Recommendation | ProductRecoStatus | undefined) => {
	return `${arg ? arg + '_' : ''}`.replace(/(\s\s+|[\t\n])/g, '').trim();
};

export const isOld = (productRecoModel: ProductRecoModel) => {
	const one_day = 1000 * 3600 * 24;
	return productRecoModel.publicationDate ? ((new Date().getTime() - productRecoModel.publicationDate?.getTime()) / one_day) > 8 : false;
};