import { InstrumentModel } from 'services/ApiService/InvestReco/InvestRecoApiClient';

export const createFetchMarketCodesAction = (query: string) => {
    return {
        type: '@ANALYTICS/FETCH_MARKET_CODES',
        payload: {
            query,
        },
    } as const;
};

export const createFetchMarketCodesSuccessAction = (query: string, response: string[]) => {
    return {
        type: '@ANALYTICS/FETCH_MARKET_CODES_SUCCESS',
        payload: {
            query,
            response,
        },
    } as const;
};

export const createFetchMarketCodesFailureAction = (query: string) => {
    return {
        type: '@ANALYTICS/FETCH_MARKET_CODES_FAILURE',
        payload: {
            query,
        },
    } as const;
};

export const createSearchInstrumentsByCodeAction = (query: string) => {
    return {
        type: '@ANALYTICS/SEARCH_INSTRUMENTS_BY_CODE',
        payload: {
            query,
        },
    } as const;
};

export const createSearchInstrumentsByCodeSuccessAction = (query: string, response: InstrumentModel[]) => {
    return {
        type: '@ANALYTICS/SEARCH_INSTRUMENTS_BY_CODE_SUCCESS',
        payload: {
            query,
            response,
        },
    } as const;
};

export const createSearchInstrumentsByCodeFailureAction = (query: string) => {
    return {
        type: '@ANALYTICS/SEARCH_INSTRUMENTS_BY_CODE_FAILURE',
        payload: {
            query,
        },
    } as const;
};

export const createClearInstrumentsByCodes = () => {
    return {
        type: '@ANALYTICS/CLEAR_INSTRUMENTS',
    } as const;
};

export const createFetchInstrumentAction = (query: string | undefined, identifierType: string) => {
    return {
        type: '@ANALYTICS/FETCH_INSTRUMENT',
        payload: {
            query,
            identifierType,
        },
    } as const;
};

export const createFetchInstrumentSuccessAction = (query: string | undefined, identifierType: string, instrument: InstrumentModel) => {
    return {
        type: '@ANALYTICS/FETCH_INSTRUMENT_SUCCESS',
        payload: {
            query,
            identifierType,
            instrument,
        },
    } as const;
};

export const createFetchInstrumentFailureAction = (query: string | undefined, identifierType: string) => {
    return {
        type: '@ANALYTICS/FETCH_INSTRUMENT_FAILURE',
        payload: {
            query,
            identifierType,
        },
    } as const;
};

export type AnalyticsActions =
    | ReturnType<typeof createFetchMarketCodesAction>
    | ReturnType<typeof createFetchMarketCodesSuccessAction>
    | ReturnType<typeof createFetchMarketCodesFailureAction>
    | ReturnType<typeof createSearchInstrumentsByCodeAction>
    | ReturnType<typeof createSearchInstrumentsByCodeSuccessAction>
    | ReturnType<typeof createSearchInstrumentsByCodeFailureAction>
    | ReturnType<typeof createClearInstrumentsByCodes>
    | ReturnType<typeof createFetchInstrumentAction>
    | ReturnType<typeof createFetchInstrumentSuccessAction>
    | ReturnType<typeof createFetchInstrumentFailureAction>