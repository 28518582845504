import { FileParameter, AccessRight, Recommendation, ProductRecoStatus } from 'services/ApiService/InvestReco/InvestRecoApiClient';
import { ApiRequest, Reducer } from 'store';

export const DEFAULT_TAKE_SIZE = 10;

export interface IInvestmentRecoByIdRequestMap {
	readonly [id: string]: {
		isFetching: boolean,
		didInvalidate: boolean,
	}
}

export interface IUserAccessRightByIdRequestMap {
	readonly [id: string]: ApiRequest<AccessRight[]>
}

export const initialState = {
	investmentRecosResponse: {
		isFetching: false as boolean,
		didInvalidate: true as boolean,
		data: undefined as string[] | null | undefined,

		searchTerms: null as string | null | undefined,
		skip: 0 as number,
		take: DEFAULT_TAKE_SIZE as number,
		totalPages: 1 as number,
		totalResults: 0 as number,

		fromPublicationDate: undefined as Date | undefined,
		untilPublicationDate: undefined as Date | undefined,

		fromLiveStatusEndDate: undefined as Date | undefined,
		untilLiveStatusEndDate: undefined as Date | undefined,

		icIds: undefined as string | undefined,
		instrumentName: undefined as string | undefined,
		includePrivate: true as boolean,
		editableOnly: true as boolean,
		identifier: undefined as string | undefined,
		recommendation: undefined as Recommendation | undefined,
		status: undefined as ProductRecoStatus | undefined,
	},
	createRequest: {
		isSubmitting: false as boolean,
	},
	updateRequest: {
		isSubmitting: false as boolean,
	},
	investmentRecoById: {} as IInvestmentRecoByIdRequestMap,
	accessRightById: {} as IUserAccessRightByIdRequestMap,
	files: [] as FileParameter[],
	publishFromSgmPubRequest: {
		isFetching: false as boolean,
		didInvalidate: true as boolean,
	} as ApiRequest,
	closeProductReco: {
		isClosing: false as boolean,
	},
} as const;

export type InvestmentRecosState = typeof initialState;

export const InvestmentRecosReducer: Reducer<InvestmentRecosState> = (
	state = initialState,
	action,
) => {
	switch (action.type) {
		case '@INVESTMENT_RECOS/FETCH_INVESTMENT_RECOS':
			return {
				...state,
				investmentRecosResponse: {
					...state.investmentRecosResponse,
					isFetching: true,
				},
			};

		case '@INVESTMENT_RECOS/FETCH_INVESTMENT_RECOS_SUCCESS':
			return {
				...state,
				investmentRecosResponse: {
					...state.investmentRecosResponse,
					isFetching: false,
					didInvalidate: false,
					data: action.payload.investmentRecosResponse,
					skip: action.payload.skip,
					take: action.payload.take,
					totalPages: action.payload.totalPages,
					totalResults: action.payload.totalResults,

					fromPublicationDate: action.payload.fromPublicationDate,
					untilPublicationDate: action.payload.untilPublicationDate,
					fromLiveStatusEndDate: action.payload.fromLiveStatusEndDate,
					untilLiveStatusEndDate: action.payload.untilLiveStatusEndDate,

					icIds: action.payload.icId,
					instrumentName: action.payload.instrumentName,
					identifier: action.payload.identifier,
					recommendation: action.payload.recommendation,
					status: action.payload.status,
				},
			};

		case '@INVESTMENT_RECOS/FETCH_INVESTMENT_RECOS_FAILURE':
			return {
				...state,
				investmentRecosResponse: {
					...state.investmentRecosResponse,
					isFetching: false,
					didInvalidate: true,
				},
			};

		case '@INVESTMENT_RECOS/SET_SEARCH_TERMS':
			const searchTermsHaveChanged = state.investmentRecosResponse.searchTerms !== action.searchTerms;
			if (!searchTermsHaveChanged) {
				return state;
			}
			return {
				...state,
				investmentRecosResponse: {
					...state.investmentRecosResponse,
					searchTerms: action.searchTerms,
					skip: action.skip,
					take: action.take,
					fromPublicationDate: action.fromPublicationDate,
					untilPublicationDate: action.untilPublicationDate,
					fromLiveStatusEndDate: action.fromLiveStatusEndDate,
					untilLiveStatusEndDate: action.untilLiveStatusEndDate,
					icIds: action.icId,
					instrumentName: action.instrumentName,
					identifier: action.identifier,
					recommendation: action.recommendation,
					status: action.status,
					isFetching: false,
					didInvalidate: true,
				},
			};

		case '@INVESTMENT_RECOS/SET_FILES':
			return {
				...state,
				files: action.payload.files,
			};

		case '@INVESTMENT_RECOS/CLEAR_FILES':
			return {
				...state,
				files: [],
			};

		case '@INVESTMENT_RECOS/FETCH_INVESTMENT_RECO_BY_ID':
			return {
				...state,
				investmentRecoById: {
					...state.investmentRecoById,
					[action.payload.id]: {
						...state.investmentRecoById[action.payload.id],
						isFetching: true,
						didInvalidate: true,
					},
				},
			};

		case '@INVESTMENT_RECOS/FETCH_INVESTMENT_RECO_BY_ID_SUCCESS':
			return {
				...state,
				investmentRecoById: {
					...state.investmentRecoById,
					[action.payload.id]: {
						...state.investmentRecoById[action.payload.id],
						isFetching: false,
						didInvalidate: false,
					},
				},
			};

		case '@INVESTMENT_RECOS/FETCH_INVESTMENT_RECO_BY_ID_FAILURE':
			return {
				...state,
				investmentRecoById: {
					...state.investmentRecoById,
					[action.payload.id]: {
						...state.investmentRecoById[action.payload.id],
						isFetching: false,
						didInvalidate: true,
					},
				},
			};

		case '@INVESTMENT_RECOS/FETCH_USER_ACCESS_RIGHTS_ON_INVESTMENT_RECO_ID':
			return {
				...state,
				accessRightById: {
					...state.accessRightById,
					[action.payload.id]: {
						isFetching: true,
						didInvalidate: true,
					},
				},
			};

		case '@INVESTMENT_RECOS/FETCH_USER_ACCESS_RIGHTS_ON_INVESTMENT_RECO_ID_SUCCESS':
			return {
				...state,
				accessRightById: {
					...state.accessRightById,
					[action.payload.id]: {
						isFetching: false,
						didInvalidate: false,
						data: action.payload.accessRight,
					},
				},
			};

		case '@INVESTMENT_RECOS/FETCH_USER_ACCESS_RIGHTS_ON_INVESTMENT_RECO_ID_FAILURE':
			return {
				...state,
				accessRightById: {
					...state.accessRightById,
					[action.payload.id]: {
						isFetching: false,
						didInvalidate: true,
					},
				},
			};

		case '@INVESTMENT_RECOS/DELETE_INVESTMENT_RECO_SUCCESS':
			return {
				...state,
				investmentRecoById: {
					...state.investmentRecoById,
					[action.payload.investRecoId]: {
						...state.investmentRecoById[action.payload.investRecoId],
						didInvalidate: true,
					},
				},
			};

		case '@INVESTMENT_RECOS/CREATE_INVESTMENT_RECO_ACTION':
			return {
				...state,
				createRequest: {
					isSubmitting: true,
				},
			};

		case '@INVESTMENT_RECOS/CREATE_INVESTMENT_RECO_SUCCESS':
			return {
				...state,
				investmentRecoById: {
					...state.investmentRecoById,
					[action.payload.created.investmentRecoHeadId]: {
						...state.investmentRecoById[action.payload.created.investmentRecoHeadId],
						isFetching: false,
						didInvalidate: false,
					},
				},
				createRequest: {
					isSubmitting: false,
				},
			};

		case '@INVESTMENT_RECOS/CREATE_INVESTMENT_RECO_FAILURE':
			return {
				...state,
				createRequest: {
					isSubmitting: false,
				},
			};

		case '@INVESTMENT_RECOS/UPDATE_INVESTMENT_RECO_ACTION':
			return {
				...state,
				updateRequest: {
					isSubmitting: true,
				},
			};

		case '@INVESTMENT_RECOS/UPDATE_INVESTMENT_RECO_SUCCESS':
			return {
				...state,
				investmentRecoById: {
					...state.investmentRecoById,
					[action.payload.id]: {
						...state.investmentRecoById[action.payload.id],
						isFetching: false,
						didInvalidate: false,
					},
				},
				updateRequest: {
					isSubmitting: false,
				},
			};

		case '@INVESTMENT_RECOS/UPDATE_INVESTMENT_RECO_FAILURE':
			return {
				...state,
				updateRequest: {
					isSubmitting: false,
				},
			};


		case '@INVESTMENT_RECOS/PUBLISH_FROM_SGM_PUB':
			return {
				...state,
				publishFromSgmPubRequest: {
					isFetching: true,
					didInvalidate: true,
				},
			};

		case '@INVESTMENT_RECOS/PUBLISH_FROM_SGM_PUB_SUCCESS':
			return {
				...state,
				publishFromSgmPubRequest: {
					isFetching: false,
					didInvalidate: false,
				},
			};

		case '@INVESTMENT_RECOS/PUBLISH_FROM_SGM_PUB_FAILURE':
			return {
				...state,
				publishFromSgmPubRequest: {
					isFetching: false,
					didInvalidate: true,
				},
			};
		
		case '@INVESTMENT_RECOS/CLOSE_PRODUCT_RECO':
			return {
				...state,
				closeProductReco: {
					isClosing: true,
				},
			};

		case '@INVESTMENT_RECOS/CLOSE_PRODUCT_RECO_SUCCESS':
			return {
				...state,
				closeProductReco: {
					isClosing: false,
				},
				investmentRecosResponse: {
					...state.investmentRecosResponse,
					isFetching: false,
					didInvalidate: true,
				},
				investmentRecoById: {
					...state.investmentRecoById,
					[action.payload.id]: {
						...state.investmentRecoById[action.payload.id],
						isFetching: false,
						didInvalidate: true,
					},
				},
			};

		case '@INVESTMENT_RECOS/CLOSE_PRODUCT_RECO_FAILURE':
			return {
				...state,
				closeProductReco: {
					isClosing: false,
				},
			};
		default:
			return state;
	}
};