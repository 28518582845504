import { includes } from 'lodash-es';
import { AccessRight } from './ApiService/InvestReco/InvestRecoApiClient';
import { ThunkDispatch } from 'components/common/AppProvider';
import { getAccessRightsForCurrentUser } from 'store/InvestmentRecos/InvestmentRecosThunk';
import { Action, State } from 'store';
import { RoutePaths } from 'routes/RoutePaths';
import { NavigateFunction } from 'react-router-dom';
import { createOpenModal } from 'store/Modals/ModalsActions';

export const currentUserHasPrivateIrRight = (currentUserPrivileges: string[]) => {
    return includes(currentUserPrivileges, 'MANAGE_MY_TEAM_INVESTMENT_RECO')
        || includes(currentUserPrivileges, 'MANAGE_ALL_INVESTMENT_RECO')
        || includes(currentUserPrivileges, 'MANAGE_MY_INVESTMENT_RECO');
};

export const currentUserHasDeletePrivilege = (currentUserPrivileges: string[]) => {
    return includes(currentUserPrivileges, 'DELETE_INVESTMENT_RECO');
};

export const currentUserCanModify = (currentUserPrivileges: AccessRight[]) => {
    return includes(currentUserPrivileges, AccessRight.Update);
};

export const currentUserCanManageAll = (currentUserPrivileges: string[] | undefined) => {
    return currentUserPrivileges && includes(currentUserPrivileges, 'MANAGE_ALL_INVESTMENT_RECO');
};

export const currentUserHasNoPrivilege = (currentUserPrivileges: string[]) => {
    return currentUserPrivileges.length == 0;
};

export const currentUserManageTeamOrOwnReco = (currentUserPrivileges: string[]) => {
    return includes(currentUserPrivileges, 'MANAGE_MY_TEAM_INVESTMENT_RECO')
        || includes(currentUserPrivileges, 'MANAGE_MY_INVESTMENT_RECO');
};

export const getCanUserCanModify = async (investmentRecoHeadId: number, dispatch: ThunkDispatch<State, Action>) => {
    const accessRights = await dispatch(getAccessRightsForCurrentUser(investmentRecoHeadId));
    return currentUserCanModify(accessRights);
};

export const accessEditPage = async (investmentRecoHeadId: number, dispatch: ThunkDispatch<State, Action>, navigate: NavigateFunction) => {
    return await getCanUserCanModify(investmentRecoHeadId, dispatch)
        ? navigate(RoutePaths.EditReco.url(investmentRecoHeadId)) 
        : dispatch(createOpenModal('insufficient-privileges'));
};

export const accessEditViewPage = async (investmentRecoHeadId: number, dispatch: ThunkDispatch<State, Action>, navigate: NavigateFunction) => {
    return await getCanUserCanModify(investmentRecoHeadId, dispatch)
        ? navigate(RoutePaths.EditReco.url(investmentRecoHeadId)) 
        : navigate(RoutePaths.ViewReco.url(investmentRecoHeadId));
};