export const createFetchAnalystsAction = (icIds: string[]) => {
    return {
        type: '@ANALYSTS/FETCH_ANALYSTS',
        payload: {
            icIds,
        },
    } as const;
};

export const createFetchAnalystsSuccessAction = (icIds: string[]) => {
    return {
        type: '@ANALYSTS/FETCH_ANALYSTS_SUCCESS',
        payload: {
            icIds,
        },
    } as const;
};

export const createFetchAnalystsFailureAction = (icIds: string[]) => {
    return {
        type: '@ANALYSTS/FETCH_ANALYSTS_FAILURE',
        payload: {
            icIds,
        },
    } as const;
};

export const createFetchAllAnalystsAction = () => {
    return {
        type: '@ANALYSTS/FETCH_ALL_ANALYSTS',
    } as const;
};

export const createFetchAllAnalystsSuccessAction = (icIds: string[]) => {
    return {
        type: '@ANALYSTS/FETCH_ALL_ANALYSTS_SUCCESS',
        payload: {
            icIds,
        },
    } as const;
};

export const createFetchAllAnalystsFailureAction = () => {
    return {
        type: '@ANALYSTS/FETCH_ALL_ANALYSTS_FAILURE',
    } as const;
};

export const createFetchAllMad2MarAnalystsAction = () => {
    return {
        type: '@ANALYSTS/FETCH_ALL_MAD2MAR_ANALYSTS',
    } as const;
};

export const createFetchAllMad2MarAnalystsSuccessAction = (icIds: string[]) => {
    return {
        type: '@ANALYSTS/FETCH_ALL_MAD2MAR_ANALYSTS_SUCCESS',
        payload: {
            icIds,
        },
    } as const;
};

export const createFetchAllMad2MarAnalystsFailureAction = () => {
    return {
        type: '@ANALYSTS/FETCH_ALL_MAD2MAR_ANALYSTS_FAILURE',
    } as const;
};

export const createFetchNavigateAsPersonsAction = () => {
    return {
        type: '@PERSONS/FETCH_NAVIGATE_AS_PERSONS',
    } as const;
};

export const createFetchNavigateAsPersonsSuccessAction = (icIds: string[]) => {
    return {
        type: '@PERSONS/FETCH_NAVIGATE_AS_PERSONS_SUCCESS',
        payload: {
            icIds,
        },
    } as const;
};

export const createFetchNavigateAsPersonsFailureAction = () => {
    return {
        type: '@PERSONS/FETCH_NAVIGATE_AS_PERSONS_FAILURE',
    } as const;
};

export type AnalystsAction =
    | ReturnType<typeof createFetchAnalystsAction>
    | ReturnType<typeof createFetchAnalystsSuccessAction>
    | ReturnType<typeof createFetchAnalystsFailureAction>
    | ReturnType<typeof createFetchAllAnalystsAction>
    | ReturnType<typeof createFetchAllAnalystsSuccessAction>
    | ReturnType<typeof createFetchAllAnalystsFailureAction>
    | ReturnType<typeof createFetchAllMad2MarAnalystsAction>
    | ReturnType<typeof createFetchAllMad2MarAnalystsSuccessAction>
    | ReturnType<typeof createFetchAllMad2MarAnalystsFailureAction>
    | ReturnType<typeof createFetchNavigateAsPersonsAction>
    | ReturnType<typeof createFetchNavigateAsPersonsSuccessAction>
    | ReturnType<typeof createFetchNavigateAsPersonsFailureAction>;